.header-container {
  box-shadow: 0px 1px 10px 2px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0px 1px 10px 2px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 1px 10px 2px rgba(0, 0, 0, 0.5);
}

.header-row {
  align-items: baseline;
}

.header-title {
  font-weight: bold;
  font-size: 24px;
  cursor: pointer;
}

.header-title-collapsed {
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
}

.notification-header {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.notification-list {
  background: white;
  z-index: 99;
  top: 35px;
  position: absolute;
  width: 300px;
  max-height: 400px;
  overflow-y: scroll;

  .ant-list-item .ant-list-item-action {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-inline-start: 10px;
  }
}
